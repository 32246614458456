<template>
  <b-container fluid="">
   <div v-if="this.instances_count===0">
    <div>
      <h3 class="text-center mt-5">Add the device for accessing the dashboard</h3>
    </div>
   </div>
     <div v-else>
        <b-tabs content-class="mt-3">
           <b-tab title="Hacker View" @click="changeTab('hacker')">
              <b-row class="content-body">
                 <b-col sm="6" md="6" lg="3">
                    <iq-card class="iq-card-block iq-card-stretch iq-card-height d-flex align-items-center justify-content-center">
                       <b-card-body class="d-flex flex-column align-items-center justify-content-center w-100">
                          <!-- Overall Health heading aligned to the left -->
                          <div class="mt-4">
                            <h5 class="text-center mb-2">Overall Health</h5>
                          </div>
                          <!-- Green circle with check mark aligned to the right -->

                          <div>
                             <div v-if="!this.overall_health" :class="'icon avatar-70 m-auto  bg-danger iq-border-danger rounded-circle'"
                                style="position: relative;">
                                <i class="ri-close-line"
                                   style="color: white; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></i>
                             </div>
                             <div v-else :class="'icon avatar-70 bg-success m-auto iq-border-success rounded-circle'"
                                style="position: relative;">
                                <i class="ri-check-line"
                                   style="color: white; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></i>
                             </div>
                          </div>
                       </b-card-body>
                    </iq-card>
                 </b-col>
                 <b-col lg="5" md="12">
                    <iq-card class="iq-card-block iq-card-stretch iq-card-height d-flex align-items-center justify-content-center">
                       <div class="d-flex flex-column align-items-center justify-content-center w-100">
                          <div class="iq-header-title">
                             <h5>Severity Levels of Detected Vulnerabilities</h5>
                          </div>
                       </div>
                       <div>
                          <b-card-body class="iq-card-body">
                             <div v-if="heatMapChart.series && heatMapChart.series.length > 0 && heatMapChart.series.some(item => item !== null)">
                                <ApexChart element="httperror-chart" :chartOption="heatMapChart" style="min-height: 168.976px"
                                   :key="heatMapChartKey" />
                             </div>
                             <div v-else>
                                <h5 class="text-center mt-5">{{ response_message }}</h5>
                             </div>
                          </b-card-body>
                       </div>
                    </iq-card>
                 </b-col>
                 <b-col sm="6" md-="6" lg="4">
                    <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                       <template v-slot:body>
                          <ul class="suggestions-lists m-0 p-0">
                             <li class="d-flex mb-4 align-items-center justify-content-between">
                                <div class="col-sm-12 p-0">
                                   <h5>CVE Exploitability Overview</h5>
                                   <div class="d-flex align-items-center">
                                      <div  class="col-sm-12 p-0">
                                         <div v-if="!exploitabilitys || exploitabilitys.length === 0" class="media-support-info ml-3">
                                            <h5 class="text-center mt-5">{{ response_message }}</h5>
                                         </div>
                                         <div v-else class="media-support-info ml-3" style=" max-height: 250px;  overflow-y: auto;">
                                            <table class="table table-striped-columnstable table-bordered text-left">
                                               <thead>
                                                  <tr>
                                                     <th scope="col">CVE ID</th>
                                                     <th scope="col">Total</th>
                                                  </tr>
                                               </thead>
                                               <tbody>
                                                  <tr v-for="exploitability in exploitabilitys" :key="exploitability.cve_id">
                                                     <td>{{ exploitability.cve_id }}</td>
                                                     <td>{{ exploitability.total }}</td>
                                                  </tr>
                                               </tbody>
                                            </table>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </li>
                          </ul>
                       </template>
                    </iq-card>
                 </b-col>
                 <b-col lg="4" md="12">
                    <iq-card class="iq-card-block iq-card-stretch">
                       <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                             <h5>Distribution of OS Assets</h5>
                          </div>
                       </div>
                       <b-card-body class="iq-card-body">
                          <div>
                             <div v-if="vulnerabilityoschart.series && vulnerabilityoschart.series.length > 0">
                                <ApexChart element="httperror-chart1" :chartOption="vulnerabilityoschart"
                                   style="min-height: 168.976px" :key="vulnerabilityoschartkey" />
                             </div>
                             <div v-else>
                                <h5 class="text-center mt-5">{{ response_message }}</h5>
                             </div>
                          </div>
                       </b-card-body>
                    </iq-card>
                 </b-col>
                 <b-col lg="4" md="12">
                    <iq-card class="iq-card-block iq-card-stretch">
                       <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                            <h5>Vulnerable OS Insights</h5>
                          </div>
                       </div>
                       <b-card-body class="iq-card-body">
                          <div>
                             <div v-if="vulnerabilitychart.series && vulnerabilitychart.series.length > 0">
                                <ApexChart element="httperror-chart2" :chartOption="vulnerabilitychart"
                                   style="min-height: 168.976px" :key="vulnerabilitychartkey" />
                             </div>
                             <div v-else>
                                <h5 class="text-center mt-5">{{ response_message }}</h5>
                             </div>
                          </div>
                       </b-card-body>
                    </iq-card>
                 </b-col>
                 <b-col lg="4">
                    <iq-card class="iq-card-block iq-card-stretch">
                       <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                             <h5>Vulnerable Groups Insights</h5>
                          </div>
                       </div>
                       <div>
                          <b-card-body class="iq-card-body">
                             <ApexChart element="activity-chart" :chartOption="vulnerabilitygroupchart"
                                :key="vulnerabilitygroupchartKey" />
                          </b-card-body>
                       </div>
                    </iq-card>
                 </b-col>
                 <b-col lg="5">
                    <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                       <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                            <h5>Top 5 Vulnerable Hosts</h5>
                          </div>
                       </div>
                       <b-card-body>
                          <div>
                             <div class="table-responsive" style=" max-height: 250px;  overflow-y: auto;">
                                <table class="table table-striped-columnstable table-bordered text-left">
                                   <thead>
                                      <tr>
                                         <th scope="col">Host</th>
                                         <th scope="col">Group</th>
                                         <th scope="col">Risk Score</th>
                                         <th scope="col">Risk</th>
                                      </tr>
                                   </thead>
                                   <tbody>
                                      <tr v-if="top_5_vulnerable_host.length === 0">
                                         <td colspan="4">
                                            <h5 class="text-center mt-5">{{ response_message }}</h5>
                                         </td>
                                      </tr>
                                      <tr v-for="host in top_5_vulnerable_host" :key="host.scan_detail__scan_result_id">
                                         <td>{{ host.host }}</td>
                                         <td>{{ host.group_name }}</td>
                                         <td>{{ host.total_score }}</td>
                                         <!-- <td>{{ host.critical }},{{ host.high }},{{ host.medium }},{{ host.low }}</td> -->
                                         <td>
                                            <div class="iq-progress-bar-linear d-inline-block mt-1 w-100">
                                               <div class="iq-progress-bar">
                                                  <b-progress :value="host.critical" class="mb-2" height="10px" variant="danger"
                                                     v-b-tooltip.hover  :title="host.critical"></b-progress>
                                                  <b-progress :value="host.high" class="mb-2" height="10px" variant="green"
                                                     v-b-tooltip.hover  :title="host.high"></b-progress>
                                                  <b-progress :value="host.medium" class="mb-2" height="10px" variant="success"
                                                     v-b-tooltip.hover  :title="host.medium"></b-progress>
                                                  <b-progress :value="host.low" class="mb-2" height="10px" variant="info" v-b-tooltip.hover
                                                     :title="host.low"></b-progress>
                                               </div>
                                            </div>
                                         </td>
                                      </tr>
                                   </tbody>
                                </table>
                             </div>
                          </div>
                       </b-card-body>
                    </iq-card>
                 </b-col>
                 <b-col lg="5">
                    <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                       <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                             <h5>Top 5 Vulnerable CVE</h5>
                          </div>
                       </div>
                       <b-card-body>
                          <div>
                             <div class="table-responsive" style=" max-height: 250px; overflow-y: auto;">
                                <table class="table table-striped-columnstable table-bordered text-left">
                                   <thead>
                                      <tr>
                                         <th scope="col">CV</th>
                                         <th scope="col">Risk Score</th>
                                         <th scope="col">Risk</th>
                                      </tr>
                                   </thead>
                                   <tbody>
                                      <tr v-if="top_5_vulnerable_cve.length === 0">
                                         <td colspan="4">
                                            <h5 class="text-center mt-5">{{ response_message }}</h5>
                                            .
                                         </td>
                                      </tr>
                                      <tr v-for="cv in top_5_vulnerable_cve" :key="cv.CVE - 2007 - 5384">
                                         <td>{{ cv.cve_id }}</td>
                                         <td>{{ cv.total_score }}</td>
                                         <!-- <td>{{ cv.critical }},{{ cv.high }},{{ cv.medium }},{{ cv.low }}</td>
                                            -->
                                         <td>
                                            <div class="iq-progress-bar-linear d-inline-block mt-1 w-100">
                                               <div class="iq-progress-bar">
                                                  <b-progress :value="cv.critical" class="mb-2" height="10px" variant="danger"
                                                     v-b-tooltip.hover  :title="cv.critical"></b-progress>
                                                  <b-progress :value="cv.high" class="mb-2" height="10px" variant="green" v-b-tooltip.hover
                                                     :title="cv.high"></b-progress>
                                                  <b-progress :value="cv.medium" class="mb-2" height="10px" variant="success"
                                                     v-b-tooltip.hover  :title="cv.medium"></b-progress>
                                                  <b-progress :value="cv.low" class="mb-2" height="10px" variant="info" v-b-tooltip.hover
                                                     :title="cv.low"></b-progress>
                                               </div>
                                            </div>
                                         </td>
                                      </tr>
                                   </tbody>
                                </table>
                             </div>
                          </div>
                       </b-card-body>
                    </iq-card>
                 </b-col>
                 <b-col lg="2">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                    <h5 class="card-title"> Blacklisted IPs</h5>
                  </div>

                </div>
                <b-card-body>
                  <div>
                    <div class="table-responsive" style=" max-height: 250px; overflow-y: auto;">
                      <table class="table mb-0 table-borderless tbl-server-info">
                        <thead>
                          <tr>
                            <th scope="col"> {{ blacklisted_count }}  IP Address</th>
                          </tr>
                        </thead >
                        <tbody v-if="black_list_ip_list.length > 0">                        
                          <tr v-for="(blacklisted_iP, index) in black_list_ip_list" :key="index">
                            <td>{{ blacklisted_iP.ip_address}}</td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <h5 class="text-center mt-5">{{ response_message }}</h5>
                        </tbody>

                      </table>
                    </div>
                  </div>
                </b-card-body>
              </iq-card>
            </b-col>
                 <b-col lg="12">
                    <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                       <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                            <h5>Latest Scanned IPs Overview</h5>
                          </div>
                          <div class="iq-card-header-toolbar d-flex align-items-center">
                             <div class="dropdown">
                                <!-- <span class="btn bg-danger">Add Monitor</span> -->
                             </div>
                          </div>
                       </div>
                       <b-card-body class="">
                          <div class="table-responsive">
                             <div style=" max-height: 250px;  overflow-y: auto;">
                                <table class="table table-striped-columnstable table-bordered text-left">
                                   <thead>
                                      <tr>
                                         <th>Host</th>
                                         <th>Os</th>
                                         <th>Os Vendor</th>
                                         <th>Accuracy</th>
                                         <th>State</th>
                                         <th>Last Scan</th>
                                      </tr>
                                   </thead>
                                   <tbody>
                                      <tr v-if="server_list.length === 0">
                                         <td colspan="6">
                                            <h5 class="text-center mt-5">{{ response_message }}</h5>
                                         </td>
                                      </tr>
                                      <tr v-for="server in server_list" :key="server.public_id">
                                         <td>
                                            <h6>{{ server.host_address }}</h6>
                                         </td>
                                         <td>
                                            <h6>{{ server.os }}</h6>
                                         </td>
                                         <td>
                                            <h6>{{ server.os_vendor }}</h6>
                                         </td>
                                         <td>
                                            <h6>{{ server.accuracy }}</h6>
                                         </td>
                                         <td>
                                            <div v-if="server.state === 'up'" class="text-success font-size-24">
                                               <i class="ri-arrow-up-fill"></i>
                                            </div>
                                            <div v-else-if="server.state === 'down'" class="text-danger font-size-24">
                                               <i class="ri-arrow-down-fill"></i>
                                            </div>
                                         </td>
                                         <td>
                                            <h6>{{ formatDateTime(server.last_scan) }}</h6>
                                         </td>
                                      </tr>
                                   </tbody>
                                </table>
                             </div>
                          </div>
                       </b-card-body>
                       <!-- Modal -->
                       <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog">
                             <div class="modal-content">
                                <div class="modal-header">
                                   <h5 class="modal-title" id="editModalLabel">Edit Server Details</h5>
                                   <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                   <form>
                                      <!-- Form fields can be added here depending on the data you need to edit -->
                                      <div class="mb-3">
                                         <label for="serverHost" class="form-label">Host</label>
                                         <input type="text" class="form-control" id="serverHost" placeholder="Enter host address">
                                      </div>
                                      <!-- Additional fields can be included as needed -->
                                   </form>
                                </div>
                                <div class="modal-footer">
                                   <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                   <button type="button" class="btn btn-primary">Save Changes</button>
                                </div>
                             </div>
                          </div>
                       </div>
                    </iq-card>
                 </b-col>
              </b-row>
           </b-tab>
           <b-tab title="Open Source Intel" @click="changeTab('Open Source Intel')">
              <b-row class="content-body">
                 <b-col sm="6" md="6" lg="3">
                    <iq-card class="iq-card-block iq-card-stretch iq-card-height d-flex align-items-center justify-content-center">
                       <b-card-body class="d-flex flex-column align-items-center justify-content-center w-100">
                          <!-- Overall Health heading aligned to the left -->
                          <div class="mt-4">
                             <h5 class="text-center mb-2">Overall Health</h5>
                          </div>
                          <div>
                             <!-- Green circle with check mark aligned to the right -->
                             <div v-if="!this.open_source_overall_health" :class="'icon avatar-70 m-auto bg-danger iq-border-danger rounded-circle'"
                                style="position: relative;">
                                <i class="ri-close-line"
                                   style="color: white; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></i>
                             </div>
                             <div v-else :class="'icon avatar-70 bg-success m-auto iq-border-success rounded-circle'"
                                style="position: relative;">
                                <i class="ri-check-line"
                                   style="color: white; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></i>
                             </div>
                          </div>
                       </b-card-body>
                    </iq-card>
                 </b-col>
                 
                 <b-col lg="5" md="12">
                  <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                      <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                              <h5>Severity Levels of Detected Vulnerabilities</h5>
                          </div>
                      </div>
                      <b-card-body class="iq-card-body d-flex align-items-center justify-content-center">
                        
                          <div>
                              <div v-if="openSourceHeatMapChart.series && openSourceHeatMapChart.series.length > 0 && openSourceHeatMapChart.series.some(item => item !== null)">
                                  <ApexChart element="httperror-chart3" :chartOption="openSourceHeatMapChart"
                                      style="min-height: 168.976px" :key="openSourceHeatMapChartKey" />
                              </div>
                              <div v-else>
                                  <h5 class="text-center mt-5">{{ response_message }}</h5>
                              </div>
                          </div>
                      </b-card-body>
                  </iq-card>
              </b-col>
                 <b-col sm="6" md="6" lg="4">
                  <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                      <template v-slot:body>
                          <ul class="suggestions-lists m-0 p-0">
                              <li class="d-flex mb-4 align-items-center justify-content-between">
                                  <div class="col-sm-12 p-0">
                                      <h5>CVE Exploitability Overview</h5>
                                      <div class="d-flex align-items-center">
                                       
                                          <div  class="col-sm-12 p-0 mt-3">
                                              <div v-if="!openSourceExploitabilitys || openSourceExploitabilitys.length === 0"
                                                  class="media-support-info ml-3">
                                                  <h5 class="text-center mt-5">{{ response_message }}</h5>
                                              </div>
                                              <div v-else
                                                  class="media-support-info ml-3 "
                                                  style=" max-height: 250px;  overflow-y: auto;">
                                                  <table
                                                      class="table table-striped-columnstable table-bordered text-left">
                                                      <thead>
                                                          <tr>
                                                              <th scope="col">CVE ID</th>
                                                              <th scope="col">Total</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr v-for="openSourceExploitability in openSourceExploitabilitys"
                                                              :key="openSourceExploitability.cve_id">
                                                              <td>{{ openSourceExploitability.cve_id }}</td>
                                                              <td>{{ openSourceExploitability.total }}</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </template>
                  </iq-card>
              </b-col>
                 <b-col lg="4" md="12">
                  <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                      <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                              <h5>Distribution of OS Assets</h5>
                          </div>
                      </div>
                      <b-card-body class="iq-card-body d-flex align-items-center justify-content-center">
                      
                          <div>
                              <div v-if="openSourceVulnerabilityoschart.series && openSourceVulnerabilityoschart.series.length > 0">
                                  <ApexChart element="httperror-chart4" :chartOption="openSourceVulnerabilityoschart"
                                      style="min-height: 168.976px" :key="openSourceVulnerabilityoschartKey" />
                              </div>
                              <div v-else>
                                  <h5 class="text-center mt-5">{{ response_message }}</h5>
                              </div>
                          </div>
                      </b-card-body>
                  </iq-card>
              </b-col>
                 <b-col lg="4" md="12">
                  <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                      <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                              <h5>Vulnerable OS Insights</h5>
                          </div>
                      </div>
                      <b-card-body class="iq-card-body d-flex align-items-center justify-content-center">
                          <div>
                              <div v-if="openSourceVulnerabilitychart.series && openSourceVulnerabilitychart.series.length > 0">
                                  <ApexChart element="httperror-chart5" :chartOption="openSourceVulnerabilitychart"
                                      style="min-height: 168.976px" :key="openSourceVulnerabilitychartkey" />
                              </div>
                              <div v-else>
                                  <h5 class="text-center mt-5">{{ response_message }}</h5>
                              </div>
                          </div>
                      </b-card-body>
                  </iq-card>
              </b-col>
                 <b-col lg="4">
                  <iq-card class="iq-card-block iq-card-stretch">
                      <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                              <h5>Vulnerable Groups Insights</h5>
                          </div>
                      </div>
                      <b-card-body class="iq-card-body d-flex align-items-center justify-content-center">
                          <div>
                              <ApexChart element="activity-chart1" :chartOption="openSourcevulnerabilitygroupchart"
                                  :key="openSourcevulnerabilitygroupchartKey" />
                          </div>
                      </b-card-body>
                  </iq-card>
              </b-col>
      
                 <b-col lg="5">
                    <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                       <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                             <h5>Top 5 Vulnerable Hosts</h5>
                          </div>
                       </div>
                       <b-card-body>
                          <div class="table-responsive" style=" max-height: 250px;  overflow-y: auto;">
                             <div>
                                <table class="table table-striped-columnstable table-bordered text-left">
                                   <thead>
                                      <tr>
                                         <th scope="col">Host</th>
                                         <th scope="col">Group</th>
                                         <th scope="col">Risk Score</th>
                                         <th scope="col">Risk</th>
                                      </tr>
                                   </thead>
                                   <tbody>
                                      <tr v-if="open_source_top_5_vulnerable_host.length === 0">
                                         <td colspan="4">
                                            <h5 class="text-center mt-5">{{ response_message }}</h5>
                                         </td>
                                      </tr>
                                      <tr v-for="open_source_host in open_source_top_5_vulnerable_host" :key="open_source_host.scan_detail__scan_result_id">
                                        <td v-if="typeof open_source_host.host === 'string'">
                                             {{ open_source_host.host.replace(/^\[|\]$/g, '') }}
                                             </td>
                                             <td v-else>
                                             {{ open_source_host.host }}
                                        </td>
                                         <td>{{ open_source_host.group_name }}</td>
                                         <td>{{ open_source_host.total_score }}</td>
                                         <!-- <td>{{ host.critical }},{{ host.high }},{{ host.medium }},{{ host.low }}</td> -->
                                         <td>
                                            <div class="iq-progress-bar-linear d-inline-block mt-1 w-100">
                                               <div class="iq-progress-bar">
                                                  <b-progress :value="open_source_host.critical" class="mb-2" height="10px" variant="danger"
                                                     v-b-tooltip.hover  :title="open_source_host.critical"></b-progress>
                                                  <b-progress :value="open_source_host.high" class="mb-2" height="10px" variant="green"
                                                     v-b-tooltip.hover  :title="open_source_host.high"></b-progress>
                                                  <b-progress :value="open_source_host.medium" class="mb-2" height="10px" variant="success"
                                                     v-b-tooltip.hover t :title="open_source_host.medium"></b-progress>
                                                  <b-progress :value="open_source_host.low" class="mb-2" height="10px" variant="info" v-b-tooltip.hover
                                                     :title="open_source_host.low"></b-progress>
                                               </div>
                                            </div>
                                         </td>
                                      </tr>
                                   </tbody>
                                </table>
                             </div>
                          </div>
                       </b-card-body>
                    </iq-card>
                 </b-col>
                 <b-col lg="5">
                    <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                       <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                            <h5>Top 5 Vulnerable CVE</h5>
                          </div>
                       </div>
                       <b-card-body>
                          <div class="table-responsive" style=" max-height: 250px; overflow-y: auto;">
                             <div>
                                <table class="table table-striped-columnstable table-bordered text-left">
                                   <thead>
                                      <tr>
                                         <th scope="col">CV</th>
                                         <th scope="col">Risk Score</th>
                                         <th scope="col">Risk</th>
                                      </tr>
                                   </thead>
                                   <tbody>
                                      <tr v-if="this.open_source_top_5_vulnerable_cve.length === 0">
                                         <td colspan="4">
                                            <h5 class="text-center mt-5">{{ response_message }}</h5>
                                         </td>
                                      </tr>
                                      <tr v-for="open_source_cv in this.open_source_top_5_vulnerable_cve" :key="open_source_cv.CVE - 2007 - 5384">
                                         <td>{{ open_source_cv.cve_id }}</td>
                                         <td>{{ open_source_cv.total_score }}</td>
                                         <!-- <td>{{ cv.critical }},{{ cv.high }},{{ cv.medium }},{{ cv.low }}</td>
                                            -->
                                         <td>
                                            <div class="iq-progress-bar-linear d-inline-block mt-1 w-100">
                                               <div class="iq-progress-bar">
                                                  <b-progress :value="open_source_cv.critical" class="mb-2" height="10px" variant="danger"
                                                     v-b-tooltip.hover  :title="open_source_cv.critical"></b-progress>
                                                  <b-progress :value="open_source_cv.high" class="mb-2" height="10px" variant="green" v-b-tooltip.hover
                                                     :title="open_source_cv.high"></b-progress>
                                                  <b-progress :value="open_source_cv.medium" class="mb-2" height="10px" variant="success"
                                                     v-b-tooltip.hover  :title="open_source_cv.medium"></b-progress>
                                                  <b-progress :value="open_source_cv.low" class="mb-2" height="10px" variant="info" v-b-tooltip.hover
                                                     :title="open_source_cv.low"></b-progress>
                                               </div>
                                            </div>
                                         </td>
                                      </tr>
                                   </tbody>
                                </table>
                             </div>
                          </div>
                       </b-card-body>
                    </iq-card>
                 </b-col>
                 <b-col lg="2">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                    <h5 class="card-title"> Blacklisted IPs</h5>
                  </div>

                </div>
                <b-card-body>
                  <div>
                    <div class="table-responsive" style=" max-height: 250px; overflow-y: auto;">
                      <table class="table mb-0 table-borderless tbl-server-info">
                        <thead>
                          <tr>
                            <th scope="col"> {{ this.open_source_black_count }}  IP Address</th>
                          </tr>
                        </thead >
                        <tbody v-if="open_source_black_list_ip_list.length > 0">                        
                          <tr v-for="(blacklisted_iP, index) in open_source_black_list_ip_list" :key="index">
                            <td>{{ blacklisted_iP.ip_address}}</td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <h5 class="text-center mt-5">{{ response_message }}</h5>
                        </tbody>

                      </table>
                    </div>
                  </div>
                </b-card-body>
              </iq-card>
            </b-col>
                 <b-col lg="12">
                    <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                       <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title">
                             <h5>Latest Scanned IPs Overview</h5>
                          </div>
                          <div class="iq-card-header-toolbar d-flex align-items-center">
                             <div class="dropdown">
                                <!-- <span class="btn bg-danger">Add Monitor</span> -->
                             </div>
                          </div>
                       </div>
                       <b-card-body class="">
                          <div class="table-responsive">
                             <div style=" max-height: 250px;  overflow-y: auto;">
                                <table class="table table-striped-columnstable table-bordered text-left">
                                   <thead>
                                      <tr>
                                         <th>Host</th>
                                         <th>Os</th>
                                         <th>Os Vendor</th>
                                         <th>Accuracy</th>
                                         <th>State</th>
                                         <th>Last Scan</th>
                                      </tr>
                                   </thead>
                                   <tbody>
                                      <tr v-if="open_source_server_list.length === 0">
                                         <td colspan="6">
                                            <h5 class="text-center mt-5">{{ response_message }}</h5>
                                         </td>
                                      </tr>
                                      <tr v-for="open_source_server in open_source_server_list" :key="open_source_server.public_id" >
                                         <td>
                                            <h6>{{ open_source_server.host_address }}</h6>
                                         </td>
                                         <td>
                                            <h6>{{ open_source_server.os }}</h6>
                                         </td>
                                         <td>
                                            <h6>{{ open_source_server.os_vendor }}</h6>
                                         </td>
                                         <td>
                                            <h6>{{ open_source_server.accuracy }}</h6>
                                         </td>
                                         <td>
                                            <div v-if="open_source_server.state === 'up'" class="text-success font-size-24">
                                               <i class="ri-arrow-up-fill"></i>
                                            </div>
                                            <div v-else-if="open_source_server.state === 'down'" class="text-danger font-size-24">
                                               <i class="ri-arrow-down-fill"></i>
                                            </div>
                                         </td>
                                         <td>
                                            <h6>{{ formatDateTime(open_source_server.last_scan) }}</h6>
                                         </td>
                                      </tr>
                                   </tbody>
                                </table>
                             </div>
                          </div>
                       </b-card-body>
                       <!-- Modal -->
                       <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog">
                             <div class="modal-content">
                                <div class="modal-header">
                                   <h5 class="modal-title" id="editModalLabel">Edit Server Details</h5>
                                   <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                   <form>
                                      <!-- Form fields can be added here depending on the data you need to edit -->
                                      <div class="mb-3">
                                         <label for="serverHost" class="form-label">Host</label>
                                         <input type="text" class="form-control" id="serverHost" placeholder="Enter host address">
                                      </div>
                                      <!-- Additional fields can be included as needed -->
                                   </form>
                                </div>
                                <div class="modal-footer">
                                   <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                   <button type="button" class="btn btn-primary">Save Changes</button>
                                </div>
                             </div>
                          </div>
                       </div>
                    </iq-card>
                 </b-col>
              </b-row>
           </b-tab>
        </b-tabs>
     </div>
  </b-container>
</template>



<script>
import { core } from '../../config/pluginInit'
import ApexChart from '../../components/core/charts/ApexChart'
import constant, { decryptToken, response_message } from "@/config/constant";
import LoadingSpinner from "@/config/Pageloader.vue"; 
import axios from "axios";
export default {
  name: 'Dashboard3',
  mounted() {
    core.index();
    this.fetchData();
    // core.bodyClassCheck('iq-page-menu-horizontal')
  },
  components: { ApexChart, LoadingSpinner },
  data() {
    return {
      instances_count: null,
      isSubmitting: false, // Define isSubmitting in the data object
      loading: true,
      last_scan:[],
      open_source_server:[],
      response_message: response_message,
      activeTab: 'hacker',
      overall_health: [],
      exploitabilitys: [],
      top_5_vulnerable_host: [],
      open_source_black_list_ip_list: [],
      black_list_ip_list: [],
      open_source_black_count: 0,
      server_list: [],
      top_5_vulnerable_cve: [],
      blacklisted_iPs: [],
      blacklisted_count: null,

      //Open Source Variable
      openSourceExploitabilitys: [],
      open_source_overall_health: [],
      open_source_top_5_vulnerable_cve: [],
      open_source_top_5_vulnerable_host: [],
      open_source_server_list: [],

      heatMapChart: {
        series: [],
        colors: ["#af0404", "#e84747", "#e3a857", "#140958"],
        labels: ["Critical", "High", "Medium", "Low"],
        chart: {
          type: "donut",
          height: 200,
          sparkline: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {},
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
      },
      heatMapChartKey: 0,

      vulnerabilityoschart: {
        series: [],
        colors: ["#af0404", "#e84747", "#e3a857", "#140958"],
        labels: [],
        chart: {
          type: "donut",
          height: 200,
          sparkline: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {},
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
      },
      vulnerabilityoschartkey: 0,

      vulnerabilitychart: {
        series: [],
        colors: ["#af0404", "#e84747", "#e3a857", "#140958"],
        labels: [""],
        chart: {
          type: "donut",
          height: 200,
          sparkline: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {},
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
      },
      vulnerabilitychartkey: 0,

      vulnerabilitygroupchart: {
        series: [{
          // name: 'name',
          data: []
        }],
        colors: ['#140958'],
        chart: {
          type: 'bar',
          height: 230
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: []
        },
        yaxis: {

        },
        fill: {
          opacity: 1
        },

      },
      vulnerabilitygroupchartKey: 0,

      //Open Source Chart
      openSourceHeatMapChart: {
        series: [],
        colors: ["#af0404", "#e84747", "#e3a857", "#140958"],
        labels: ["Critical", "High", "Medium", "Low"],
        chart: {
          type: "donut",
          height: 200,
          sparkline: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {},
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
      },
      openSourceHeatMapChartKey: 0,

      openSourceVulnerabilityoschart: {
        series: [],
        colors: ["#af0404", "#e84747", "#e3a857", "#140958"],
        labels: [],
        chart: {
          type: "donut",
          height: 200,
          sparkline: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {},
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
      },
      openSourceVulnerabilityoschartKey: 0,
      openSourceVulnerabilitychart: {
        series: [],
        colors: ["#af0404", "#e84747", "#e3a857", "#140958"],
        labels: [""],
        chart: {
          type: "donut",
          height: 200,
          sparkline: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {},
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
      },
      openSourceVulnerabilitychartkey: 0,
      openSourcevulnerabilitygroupchart: {
        series: [{
          // name: 'name',
          data: []
        }],
        colors: ['#140958'],
        chart: {
          type: 'bar',
          height: 230
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: []
        },
        yaxis: {

        },
        fill: {
          opacity: 1
        },

      },
      openSourcevulnerabilitygroupchartKey: 0,
    }
  },
  computed: {
    tabType() {
      return this.activeTab;
    }
  },
  watch: {
    tabType: {
      immediate: true,
      handler() {
        this.fetchVulnerability();
        this.fetchServerList();
        this.fetchVmsgraphs();
        this.fetchBlacklistIp();
      },
    },
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
    async fetchData() {
      //Fetch Devices
      try {
        const token = localStorage.getItem("token");
        const key = "enc"; // Fetch encrypted token
        const decryptedToken = decryptToken(token, key);

        const response = await axios.get(
          constant.API_BASE_URL + "vms/instances/", {
          headers: {
            Authorization: "Token " + decryptedToken,
          },
        });
        this.instances_count = response.data.count
      } catch (error) {
        this.loading = false;
      }
    },
    formatDateTime(dateTimeString) {
      const date = new Date(dateTimeString);
      if (isNaN(date)) {
    // अगर तिथि अमान्य है, तो एक डिफ़ॉल्ट तिथि समय मान सेट करें।
    return "Invalid Date";
  }
      const day = date.getDate();
      const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
      const year = date.getFullYear();
      let hour = date.getHours();
      const minute = date.getMinutes();
      const ampm = hour >= 12 ? 'PM' : 'AM';

      // Convert hour to 12-hour format
      hour = hour % 12 || 12;

      const formattedDateTime = `${day}-${month}-${year}, ${hour}:${minute.toString().padStart(2, '0')} ${ampm}`;
      return formattedDateTime;
    },
    async fetchVulnerability() {
      try {
        this.loading = true;
        this.exploitabilitys = [];
        this.top_5_vulnerable_host = [];
        this.top_5_vulnerable_cve = [];
        this.openSourceExploitabilitys = [];
        this.open_source_top_5_vulnerable_host = [];
        this.open_source_top_5_vulnerable_cve = [];
        
        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);

        let type = 'hacker'; // default type for 'Hacker View' tab
        if (this.activeTab === 'Open Source Intel') {
          type = 'open';
        }
        const response = await axios.get(
          constant.API_BASE_URL +
          "vms/top-vulnerabilities/?type=" +
          type,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );

        if (this.activeTab === 'Open Source Intel') {
          this.openSourceExploitabilitys = response.data.data.exploits_cve;//exploitabilitys data
          this.open_source_top_5_vulnerable_host = response.data.data.top_5_vulnerable_host; //Top Vulnerability assets data
          this.open_source_top_5_vulnerable_cve = response.data.data.top_5_vulnerable_cve; //Top Vulnerability 
        } else {
          this.exploitabilitys = response.data.data.exploits_cve;
          this.top_5_vulnerable_host = response.data.data.top_5_vulnerable_host; 
          this.top_5_vulnerable_cve = response.data.data.top_5_vulnerable_cve;
        }
        
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchBlacklistIp() {
      try {
        this.loading = true;
        this.black_list_ip_list = [];
        this.blacklisted_count = null;
        this.open_source_black_list_ip_list = [];
        this.open_source_black_count = 0;

        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        let type = 'hacker'; // default type for 'Hacker View' tab
        if (this.activeTab === 'Open Source Intel') {
          type = 'open';
        }
        const response = await axios.get(
          constant.API_BASE_URL + "vms/instance-blacklists/?type=" +
          type,
          { headers: { Authorization: "Token " + decryptedToken } }
        );
        if (this.activeTab === 'Open Source Intel') {
          this.open_source_black_list_ip_list = response.data.data;
          this.open_source_black_count = response.data.count;
        } else {
          this.black_list_ip_list = response.data.data;
          this.blacklisted_count = response.data.count;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchVmsgraphs() {
      try {
        this.loading = true;
        this.overall_health = [];
        this.heatMapChart.series = [];
        this.heatMapChartKey++;
        this.vulnerabilityoschart.series = [];
        this.vulnerabilityoschart.labels = [];
        this.vulnerabilityoschartkey++;
        this.vulnerabilitychart.series = [];
        this.vulnerabilitychart.labels = [];
        this.vulnerabilitychartkey++;
        this.vulnerabilitygroupchart.series[0].data = [];
        this.vulnerabilitygroupchart.xaxis.categories = [];
        this.vulnerabilitygroupchartKey++;

        this.open_source_overall_health = [];
        this.openSourceHeatMapChart.series = [];
        this.openSourceHeatMapChartKey++;
        this.openSourceVulnerabilityoschart.series = [];
        this.openSourceVulnerabilityoschart.labels = [];
        this.openSourceVulnerabilityoschartKey++;
        this.openSourceVulnerabilitychart.series = [];
        this.openSourceVulnerabilitychart.labels = [];
        this.openSourceVulnerabilitychartkey++;
        this.openSourcevulnerabilitygroupchart.series[0].data = [];
        this.openSourcevulnerabilitygroupchart.xaxis.categories = [];
        this.openSourcevulnerabilitygroupchartKey++;

        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        let type = 'hacker'; // default type for 'Hacker View' tab
        if (this.activeTab === 'Open Source Intel') {
          type = 'open';
        }
        const response = await axios.get(
          constant.API_BASE_URL +
          "vms/graphs/?type=" +
          type,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );

        if (this.activeTab === 'Open Source Intel') {
          this.open_source_overall_health = response.data.data.health; //Open Source Overall Health  data Show 

          const severityLevels = Object.values(response.data.data.severity_level);  //Open Source  Heat Map  data Show 
          this.openSourceHeatMapChart.series = severityLevels;
          this.openSourceHeatMapChartKey++;

          const assets_by_os_value = response.data.data.assets_by_os.map(item => item.value);  //Open Source  Vulnerability by OS data
          const assets_by_os_name = response.data.data.assets_by_os.map(item => item.os_vendor || 'Unknown');
          this.openSourceVulnerabilityoschart.series = assets_by_os_value;
          this.openSourceVulnerabilityoschart.labels = assets_by_os_name;
          this.openSourceVulnerabilityoschartKey++;

          const os_vulnerabilities_value = response.data.data.os_vulnerabilities.map(item => item.total_vulnerability);  //Open Source  Vulnerability data show 
          this.openSourceVulnerabilitychart.series = os_vulnerabilities_value;
          const os_vulnerabilities_name = response.data.data.os_vulnerabilities.map(item => item.os_vendor || 'Unknown');
          this.openSourceVulnerabilitychart.labels = os_vulnerabilities_name;
          this.openSourceVulnerabilitychartkey++;

          const groups_vulnerabilities_value = response.data.data.groups_vulnerabilities.map(item => item.total_vulnerability); //Open Source  Vulnerability by groups  
          const groups_vulnerabilities_name = response.data.data.groups_vulnerabilities.map(item => item.group_name);
          this.openSourcevulnerabilitygroupchart.series[0].data = groups_vulnerabilities_value;
          this.openSourcevulnerabilitygroupchart.xaxis.categories = groups_vulnerabilities_name;
          this.openSourcevulnerabilitygroupchartKey++;
        } else {
          this.overall_health = response.data.data.health; //Overall Health  data Show 

          const severityLevels = Object.values(response.data.data.severity_level); //Heat Map  data Show 
          this.heatMapChart.series = severityLevels;
          this.heatMapChartKey++;

          const assets_by_os_value = response.data.data.assets_by_os.map(item => item.value); //Open Source  Vulnerability by OS data
          const assets_by_os_name = response.data.data.assets_by_os.map(item => item.os_vendor || 'Unknown');
          this.vulnerabilityoschart.series = assets_by_os_value;
          this.vulnerabilityoschart.labels = assets_by_os_name;
          this.vulnerabilityoschartkey++;

          const os_vulnerabilities_value = response.data.data.os_vulnerabilities.map(item => item.total_vulnerability); // Vulnerability data show 
          this.vulnerabilitychart.series = os_vulnerabilities_value;
          const os_vulnerabilities_name = response.data.data.os_vulnerabilities.map(item => item.os_vendor || 'Unknown');
          this.vulnerabilitychart.labels = os_vulnerabilities_name;
          this.vulnerabilitychartkey++;

          const groups_vulnerabilities_value = response.data.data.groups_vulnerabilities.map(item => item.total_vulnerability); // Vulnerability by groups  
          const groups_vulnerabilities_name = response.data.data.groups_vulnerabilities.map(item => item.group_name);
          this.vulnerabilitygroupchart.series[0].data = groups_vulnerabilities_value;
          this.vulnerabilitygroupchart.xaxis.categories = groups_vulnerabilities_name;
          this.vulnerabilitygroupchartKey++;
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },

    // Method to fetch website names from API

    async fetchServerList() {
      try {
        this.loading = true;
        this.server_list = [];
        this.open_source_server_list = [];

        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        let type = 'hacker'; // default type for 'Hacker View' tab
        if (this.activeTab === 'Open Source Intel') {
          type = 'open';
        }
        const response = await axios.get(
          constant.API_BASE_URL +
          "vms/scan-details/?type=" +
          type,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );
        //Server List data show 
        if (this.activeTab === 'Open Source Intel') {
          this.open_source_server_list = response.data.data;
        } else {
          this.server_list = response.data.data;
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },

  },
}
</script>

   
